.main-header{    
    padding: 20px 0;        
    position: sticky;
    top: 0;        
    z-index: 1;
    .container{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .burguer{
      display: none;            
      -webkit-user-select: none;
      user-select: none;
      position: relative;

      @media (max-width: 768px) {
        display: block;   
      }

      span{
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        
        background: #FFF;
        border-radius: 3px;
        
        z-index: 1;
        
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;

        &:first-child{
          transform-origin: 0% 0%;
        }
        &:nth-last-child(2){
          transform-origin: 0% 100%;
        }
      }   
      
      input{
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        
        cursor: pointer;
        
        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */
        
        -webkit-touch-callout: none;        
        &:checked ~ .nav-mobile{
          transform: none;
        }

        &:checked ~ span{
          opacity: 1;
          transform: rotate(45deg) translate(-2px, -1px);
          background: #FFF;

          &:nth-last-child(3){
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }

          &:nth-last-child(2){
            transform: rotate(-45deg) translate(0, -1px);
          }
        }   
      }      
    }
    nav{
      &.desk{
        @media (max-width: 768px) {
          display: none;   
        }
      }
      &.nav-mobile{
        position: fixed;
        width: 100%;
        // margin: -100px 0 0 -50px;        
        padding-top: 225px;
        top: 0;
        right: 0;
        background: transparent;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;        
        /* to stop flickering of text in safari */
        
        transform-origin: 0% 0%;
        transform: translateX(100%);
        
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        z-index: 1;
        .menu{
          flex-direction: column;

          li{
            margin: 20px 0;
          }
        }
      }
      .header-menu{
        display: flex;
        align-items: center;
        justify-content: center;        
        ul{
          display: inline-flex;
          justify-content: flex-start;
          li{
              margin: 0 20px 0 0;
              a{
                color: #fff;
                text-decoration: none;
                border-radius: 50px;
                padding: 20px 25px;
                @include font-size(13px);
                text-transform: uppercase;
                transition: all .3s ease;
              }
  
              &.current-menu-item{
                a{
                  background-color: rgba(255,255,255,0.3);
                }
              }
          } 
        }          
        
      }
    }
}